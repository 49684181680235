import Name from "./Name/Name";
import Contacts from "./Contacts/Contacts";
const Header = () => (
  <div className="container row">
    <Name />
    <Contacts />
  </div>
);

export default Header;
