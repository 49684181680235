import Header from "./Header/Header";
import Summary from "./Summary/Summary";
import Experiences from "./Experience/Experiences";
import Education from "./Education/Education";
import Skills from "./Skills/Skills";
import Footer from "./Footer/Footer";

import "./Resume.css";

function Resume() {
  return (
    <div>
      <Header />
      <Summary />
      <Experiences />
      <Education />
      <Skills />
      <Footer />
    </div>
  );
}

export default Resume;
