import Qualification from "./Qualification";
import configData from "../../config.json";

function Education() {

  const qualifications = Object.keys(configData.qualifications).map((key) => {
    return (
      <Qualification
        date={configData.qualifications[key].date}
        degree={configData.qualifications[key].degree}
        university={configData.qualifications[key].university}
        description={configData.qualifications[key].description}
      />
    );
  });

  return (
    <div className="container">
      <div className="header">Education</div>
      {qualifications}
    </div>
  );
}

export default Education;
