const Experience = (props) => (
  <div className="section-container">
    <div className="section-title">{props.organisation}</div>
    <hr />
    <div>
      <span className="section-subtitle">{props.position} </span>
    </div>
    <div className="section-date">{props.period}</div>
    <div className="section-description">{props.description}</div>
    <div className="section-additional-info-label">Technical Expertise: </div>
    <div className="section-additional-info">{props.technology}</div>
  </div>
);

export default Experience;
