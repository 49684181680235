const Qualification = (props) => (
  <div className="section-container">
    <div className="section-title">{props.degree}</div>
    <hr />
    <div className="section-subtitle">{props.university}</div>
    <div className="section-date">{props.date}</div>
    <div className="section-description">{props.description}</div>
  </div>
);

export default Qualification;
