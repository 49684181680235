import Experience from "./Experience";
import configData from '../../config.json'

function Experiences() {
  const experiences = Object.keys(configData.experiences).map((key) => {
    return (
      <Experience
        period={configData.experiences[key].period}
        position={configData.experiences[key].position}
        organisation={configData.experiences[key].organisation}
        description={configData.experiences[key].description}
        technology={configData.experiences[key].technology}
      />
    );
  });

  return (
    <div className="container">
      <div className="header">Work experience</div>
      {experiences}
    </div>
  );
}

export default Experiences;
