import "./Skills.css";
import configData from "../../config.json";
function Skills() {
  const skills = Object.keys(configData.skills).map((key) => {
    return <li className="skills-list">{configData.skills[key].skill}</li>;
  });

  return (
    <div className="container">
      <div className="header">Skills</div>
      <ul className="skills-ul">{skills}</ul>
    </div>
  );
}

export default Skills;
