import React from "react";
import ReactDOM from "react-dom";
import Resume from "./components/Resume";

ReactDOM.render(
  <React.StrictMode>
    <Resume />
  </React.StrictMode>,
  document.getElementById("root")
);
