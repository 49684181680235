import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeOpen,
  faGlobe,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "./Contacts.css";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Contacts = () => (
  <div className="contacts-container">
    <div className="contacts-info">
      Sydney, Australia &nbsp; <FontAwesomeIcon icon={faLocationDot} />
    </div>
    <div className="contacts-info">
      0469054774 &nbsp; <FontAwesomeIcon icon={faPhone} />
    </div>
    <div className="contacts-info">
      tejas@programmer.net &nbsp; <FontAwesomeIcon icon={faEnvelopeOpen} />
    </div>
    <div className="contacts-info">
      <a href="https://au.linkedin.com/in/tejasunnikrishnan">
        tejasunnikrishnan
      </a>{" "}
      &nbsp; <FontAwesomeIcon icon={faLinkedin} />
    </div>
    <div className="contacts-info">
      <a href="https://tejas.uk">https://tejas.uk</a> &nbsp;{" "}
      <FontAwesomeIcon icon={faGlobe} />
    </div>
  </div>
);

export default Contacts;
