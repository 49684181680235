import configData from '../../config.json'
const Footer = () => (
  <div className="container">
    <div>
      Need a downladable format? Please send me an{" "}
      <a href={configData.email}>email</a>.
    </div>
    <div>
      Theme designed by me. Built using create-react-app. Uploaded to Github.com. Hosted using Cloudfare.
    </div>
    <div>
      All rights reserved with <a href={configData.website}>{configData.website}</a>
    </div>
  </div>
);

export default Footer;
